






































import { Parametro } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { ParametroService } from "@/core/services/geral";
import { Component, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { ArquivoService } from "@/core/services/shared";

@Component
export default class ListaParametro extends PageBase{
    
    item: Parametro = new Parametro();
    itemOriginal!: Parametro;
    parametroService = new ParametroService();
    service = new ParametroService();
    loading: boolean = false;
    certificado: any = null;

    @Watch('item.id')
    WatchItem(){
        this.itemOriginal = jiff.clone(this.item);
    }

    mounted() {
        this.Carregar(); 
    }

    async LoadCertificado(){

        if (!this.certificado)
            return;

        const arquivoService = new ArquivoService();
        const dados = await arquivoService.Ler(this.certificado);
        this.item.certificado = dados.replace(/^[^,]*,/, "");
    }

    RemoveCertificado(){
        this.item.certificado = null;
        this.certificado = null;
    }

    Carregar(){
        this.loading = true;

        this.parametroService.ObterPorId(1).then(
            res => {
                this.item = res.data;
            },
            err => {
            if (!err.response){
                if(!navigator.onLine){
                    this.$swal("Aviso", "Não foi possível se conectar com a internet", "error");
                }
                else{
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                }
            }
            else{
                this.$swal("Aviso", err.response.data, "error");
            }
        })
        .finally(() => (this.loading = false));
    }

    Salvar(){
        let patchModel = jiff.diff(this.itemOriginal,this.item,false);

        this.service.Salvar(patchModel,this.item.id).then(
            res => {
                this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200? "success" : "warning");
                this.Carregar();
            },
            err =>{
                this.$swal("Aviso", err.response.data, "error");
            }
        )
    }
}
